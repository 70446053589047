import React from 'react'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import ImageComponent from './GeneralComponents/ImageComponent'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import NavigateNext from '@material-ui/icons/NavigateNext'
import NavigateBefore from '@material-ui/icons/NavigateBefore'

const PreviewDialog = ({ index = 0, list, onClose }) => {
    const [previewIndex, setPreviewIndex] = React.useState(index)
    return <div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton onClick={onClose}>
                <CloseIcon fontSize="large" htmlColor='#FFF' />
            </IconButton>
        </div>
        <div style={{ position: 'relative', height: "calc(100vh - 110px)", display: 'flex', justifyContent: 'center', padding: '0px 20px' }}>
            {
                list[previewIndex] &&
                <ImageComponent
                    src={list[previewIndex].url}
                    alt={list[previewIndex].url}
                    style={{
                        maxHeight: '100%',
                        maxWidth: '100%',
                        objectFit: "contain"
                    }}
                />
            }
            <div style={{
                position: 'absolute',
                top: "50%", left: '20px',
                transform: 'translateY(-50%)',
                zIndex: 1
            }}>
                <IconButton style={{ backgroundColor: "rgba(0,0,0,0.8)" }} onClick={() => setPreviewIndex(prev => prev === 0 ? list.length - 1 : prev - 1)}>
                    <NavigateBefore fontSize="large" htmlColor='#FFF' />
                </IconButton>
            </div>
            <div style={{
                position: 'absolute',
                top: "50%", right: '20px',
                transform: 'translateY(-50%)',
                zIndex: 1
            }}>
                <IconButton style={{ backgroundColor: "rgba(0,0,0,0.8)" }} onClick={() => setPreviewIndex(prev => prev === list.length - 1 ? 0 : prev + 1)}>
                    <NavigateNext fontSize="large" htmlColor='#FFF' />
                </IconButton>
            </div>
        </div>
    </div>

}

export default function ImageGallery({ list = [] }) {
    const [openDialog, setOpenDialog] = React.useState({ show: false, index: 0 })
    return <>
        <Grid container spacing={4} justifyContent='center'>
            {
                list.map((item, index) => {
                    return <Grid item key={item.url}>
                        <div style={{
                            cursor: 'pointer',
                            border: '4px solid #B3CFF0'
                        }}
                            onClick={() => {
                                setOpenDialog({ show: true, index })
                            }}
                        >
                            <ImageComponent
                                src={item.url}
                                alt={item.url}
                                style={{
                                    height: "200px",
                                    width: "100%",
                                    objectFit: 'contain'
                                }}
                            />
                        </div>
                    </Grid>
                })
            }
        </Grid>
        <Dialog
            style={{
                zIndex: 9999
            }}
            PaperProps={{
                style: {
                    boxSizing: "border-box",
                    backgroundColor: "transparent",
                    height: "100vh",
                    width: "100vw",
                    display: 'flex'
                }
            }}
            open={openDialog.show} onClose={() => setOpenDialog(prev => ({ ...prev, show: false }))} fullScreen>
            <PreviewDialog index={openDialog.index} list={list} onClose={() => setOpenDialog(prev => ({ ...prev, show: false }))} />
        </Dialog>
    </>
}